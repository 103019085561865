
@import "~@/styles/variables.scss";

.root {
  position: relative;
  border-radius: 10px;
  height: 40px;
  color: $color-white;
  background-color: $color-blue;
  cursor: pointer;
}

.button {
  border: none;
  background: transparent;
  width: 100%;
  height: 100%;
  color: inherit;
  cursor: inherit;
  font-weight: inherit;
}

.preloader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

.rootLight {
  composes: root;
  background: rgba(220, 228, 249, 1);
  color: rgba(9, 15, 30, 1);
  font-weight: 600;
  padding: 0 4px;
}

.rootConfirm {
  composes: root;
  background: linear-gradient(88.93deg, #31BC69 1.83%, #089E4E 98.17%);
}

.rootCancel {
  composes: root;
  background-color: rgba($color-white, .1);
}

.rootError {
  composes: root;
  background-color: $color-red;
}
